/*
  Grid templates:
  - `header` height and `nav` width will be as small as their content allows.
  - The `content` area will fill up the remaining height and width.
*/

.app {
  background-color: var(--magnetic-neutral-2);
  display: grid;
  height: 100vh;
  grid-template-areas:
    "header header"
    "nav content";
  grid-template-columns:
    min-content
    minmax(auto, 1fr);
  grid-template-rows:
    min-content
    minmax(auto, 1fr);
  overflow: hidden;

  & > :global(*) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.content {
  grid-area: content;
  overflow-y: auto;
  padding: 0;
  position: relative;
}

.hidden {
  display: none;
  visibility: hidden;
}

:global(.theme--dusk) .app {
  background-color: var(--magnetic-neutral-17);
}
