// TODO see if we can get default padding / margins from the base theme eventually
$margin: 10px;
$minLabelWidth: 40px;

.boldMenuFont {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.lastLogged {
  margin: 0;
  font-size: 14px;
  color: var(--brand-text);
}

.toggleRight {
  margin-left: $margin;
  min-width: $minLabelWidth;
  text-align: left;
}

.toggleLeft {
  margin-right: $margin;
  min-width: $minLabelWidth;
  text-align: right;
}

.textInputWrapper {
  input {
    border: none !important;
  }
}
