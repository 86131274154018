/**
* Custom styles for XDR AI Toast that overrides CDS toast styles if toast contains a specific id
* that starts with 'ai-xdr-toast-'
*/
.xdrToast {
  --gradient: linear-gradient(#0ec0e7, #3a73e4);
  --border: 6px;

  :global([class^="cds-toaster"]) {
    :global([id^="ai-xdr-toast-"]) {
      position: relative;
      border: none !important;
      border-radius: 0 var(--border) var(--border) 0 !important;
      background-image: url("/icons/ai/ai-icon.svg");
      background-repeat: no-repeat;
      background-position: 13px 17px;

      &::before {
        position: absolute;
        left: calc(var(--border) * -1);
        right: 0;
        top: 0;
        bottom: 0;
        content: "";
        z-index: -1;
        background: var(--gradient);
        border-radius: var(--border) 0 0 var(--border);
        width: var(--border);
      }

      :global(.cds-status-icon) {
        visibility: hidden !important;
      }
    }

    .aiToastButtonContainer {
      margin-top: 15px;
      background: var(--gradient);
      padding: 2px;
      width: fit-content;
      border-radius: 8px;

      .aiToastButton {
        display: block;
        padding: 5px 8px;
        border: none;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
      }
    }
  }
}

:root[data-color-scheme="light"] {
  :global([id^="ai-xdr-toast-"]) {
    .aiToastDescription,
    .aiToastButton,
    .aiToastButton:hover {
      color: #23282e;
    }
  }
}

:root[data-color-scheme="dark"] {
  :global([id^="ai-xdr-toast-"]) {
    .aiToastDescription,
    .aiToastButton,
    .aiToastButton:hover {
      color: #f7f7f7;
    }
  }
}
