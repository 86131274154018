.modal {
  color: unset;
  display: block;
  padding: 0 !important;
  text-align: center;
}

.body {
  padding: 35px 60px 0px;

  .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
}

.footer {
  padding: 24px;
}
