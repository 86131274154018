.header {
  grid-area: header;
  min-width: unset !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  position: fixed;
  z-index: 400;

  // FIX for CDS styles colliding w/ MFE styles
  :global(.cds-header__logo > a) {
    margin-left: 3px;
    align-items: center;
    background-color: transparent !important;
    display: inline-flex;
    justify-content: center;
    text-decoration: none;
  }

  // FIX for CDS styles removed here:
  // https://github.com/CiscoDesignSystems/magnetic-common-design-system/commit/b33edf83c2206a78e9af98d6e089c54d26411de7
  :global(.cds-tenant-menu__row__gutter) {
    min-width: 24px;
  }

  // FIX for MFE styles colliding w/ CDS styles
  [data-cds-tenant-switcher] {
    text-decoration: none;
  }
}

.spacer {
  grid-area: header;
  height: 56px;
}

.logo {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.headerButtons {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;

  // Fixes an issue where hovered state has a black border
  :global(#xdr-header-integrations .xdr-header-integrations) > button {
    border: none;
  }
}
