$spinner-color: var(--magnetic-neutral-9);
$spinner-diameter: 72px;
$spinner-timing-function: linear;
$spinner-transition-duration: 1s;
$spinner-width: 10px;

.wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.spinner {
  animation: spin $spinner-transition-duration $spinner-timing-function infinite;
  border-color: $spinner-color transparent transparent transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: $spinner-width;
  font-size: $spinner-width;
  height: $spinner-diameter;
  position: relative;
  width: $spinner-diameter;

  &:before,
  &:after {
    background: $spinner-color;
    border-radius: 50%;
    content: "";
    height: $spinner-width;
    position: absolute;
    top: 0.2em;
    width: $spinner-width;
  }

  &:before {
    left: 0.2em;
  }

  &:after {
    right: 0.2em;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
