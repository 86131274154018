.themeIcon {
  height: 24px;
  position: relative;
  width: 24px;

  > svg {
    position: absolute;
  }

  .sun {
    transition: transform 200ms ease-in, opacity 150ms ease-in 50ms;

    &.active {
      opacity: 1;
      transform: rotate(0) scale(1);
    }

    &.inactive {
      opacity: 0;
      transform: rotate(-22.5deg) scale(1.5);
    }
  }

  .moon {
    transition: transform 200ms ease-in, opacity 150ms ease-in;

    &.active {
      opacity: 1;
      transform: scale(1);
    }

    &.inactive {
      opacity: 0;
      transform: scale(0.1);
    }
  }
}
