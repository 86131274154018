.container {
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding-top: 12vh;
  height: calc(88vh - 56px);
  width: 340px;
}

.image {
  margin-left: 34px;
}

.header {
  margin: 40px 0 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.message {
  margin: 12px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.button {
  margin: 12px 0;
}
