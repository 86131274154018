.popover {
  --width: 280px;
  --offset: calc(var(--width) * -0.4125); // about 20px
  transform: translateX(var(--offset));
  width: var(--width);

  > svg:first-of-type {
    transform: rotate(180deg) translateX(var(--offset)) !important;
  }
}

.popoverContentTitle {
  font-weight: 600;
  margin: 0;
}

.buttonGroup {
  justify-content: flex-end;
}
