@import "@ciscodesignsystems/cds-react-badge/index.css";
@import "@ciscodesignsystems/cds-react-button/index.css";
@import "@ciscodesignsystems/cds-react-header/index.css";
@import "@ciscodesignsystems/cds-react-logos/index.css";
@import "@ciscodesignsystems/cds-react-menu/index.css";
@import "@ciscodesignsystems/cds-react-modal/index.css";
@import "@ciscodesignsystems/cds-react-nav/index.css";
@import "@ciscodesignsystems/cds-react-notification/index.css";
@import "@ciscodesignsystems/cds-react-popover/index.css";
@import "@ciscodesignsystems/cds-react-tag/index.css";
@import "@ciscodesignsystems/cds-react-text-input/index.css";
@import "@ciscodesignsystems/cds-react-toaster/index.css";
@import "@ciscodesignsystems/cds-react-toggle/index.css";
@import "@ciscodesignsystems/cds-react-tooltip/index.css";

@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("https://brand-assets.security.cisco.com/fonts/inter/Inter-roman.var.woff2?v=3.19")
    format("woff2");
}

:root {
  --magnetic-neutral-1: #ffffff;
  --magnetic-neutral-2: #f7f7f7;
  --magnetic-neutral-3: #f0f1f2;
  --magnetic-neutral-4: #e1e4e8;
  --magnetic-neutral-5: #d0d4d9;
  --magnetic-neutral-6: #c1c6cc;
  --magnetic-neutral-7: #a7adb5;
  --magnetic-neutral-8: #979fa8;
  --magnetic-neutral-9: #889099;
  --magnetic-neutral-10: #7e868f;
  --magnetic-neutral-11: #6f7680;
  --magnetic-neutral-12: #656c75;
  --magnetic-neutral-13: #596069;
  --magnetic-neutral-14: #464c54;
  --magnetic-neutral-15: #373c42;
  --magnetic-neutral-16: #23282e;
  --magnetic-neutral-17: #0f1214;
  --magnetic-neutral-18: #000000;
  --magnetic-blue-1: #edf4ff;
  --magnetic-blue-2: #d9e7ff;
  --magnetic-blue-3: #ccdefc;
  --magnetic-blue-4: #a6c4f5;
  --magnetic-blue-5: #7aa7f5;
  --magnetic-blue-6: #5b92f0;
  --magnetic-blue-7: #4580e5;
  --magnetic-blue-8: #326cd1;
  --magnetic-blue-9: #235ec4;
  --magnetic-blue-10: #1650b5;
  --magnetic-blue-45: #1d69cc;
  --magnetic-blue-50: #2774d9;
  --magnetic-blue-55: #3e84e5;
  --magnetic-blue-60: #5191f0;
  --magnetic-blue-70: #7cadf7;
  --grey-text: #707070;

  &[data-color-scheme="light"] {
    color-scheme: light;
    background-color: var(--magnetic-neutral-2);

    body {
      background-color: var(--magnetic-neutral-2) !important;
      color: var(--magnetic-neutral-15) !important;
      .grey-text-color {
        color: var(--grey-text) !important;
      }
      .blue-text-color {
        color: var(--magnetic-blue-8) !important;
      }
    }
  }

  &[data-color-scheme="dark"] {
    color-scheme: dark;
    background-color: var(--magnetic-neutral-17);

    body {
      background-color: var(--magnetic-neutral-17) !important;
      color: var(--magnetic-neutral-4) !important;
    }
  }

  body {
    font-family: "Inter", sans-serif !important;
    margin: 0 !important;

    @supports (font-variation-settings: normal) {
      font-family: "Inter var", "Inter", sans-serif !important;
    }
  }
}

/* CDS overrides */

// bugfixes for @ciscodesignsystems/cds-react-modal@0.8.0
.cds-modal-overlay {
  z-index: 1000;
}

.cds-modal {
  box-shadow: 0 4px 12px #0000001f;
}

// match toast widths from magna-react
.cds-toaster {
  min-width: 260px;
  max-width: 465px;

  // account for xdr header
  &.cds-toaster--placement-top-right {
    top: 70px;
  }

  // adds margin under the toast heading
  .cds-notification__heading {
    margin-bottom: 5px;
  }
}
