.wrapper {
  background-color: inherit;
  grid-area: nav;
  padding: 0 !important; // FIXME
  // z-index: 800; This fixes the nav showing above menus, but has consequences for tooltips and modals

  --brand-accent-icon-active: var(--magnetic-blue-55) !important;
  --brand-accent-icon-weak-active: var(--magnetic-blue-70) !important;
  --brand-accent-icon-medium-active: var(--magnetic-blue-60) !important;
  --brand-accent-border-active: var(--magnetic-blue-45) !important;

  :global(.cds-nav__menu__link) {
    color: var(--magnetic-neutral-16);

    :global(.cds-nav__menu__link__text .cds-tag) {
      display: inline-block;
      margin-left: 8px;
      background-color: #ffeadb;
    }
  }
}

:global(.theme--dusk) {
  .wrapper {
    --brand-accent-icon-active: var(--magnetic-blue-70) !important;
    --brand-accent-icon-weak-active: var(--magnetic-blue-50) !important;
    --brand-accent-icon-medium-active: var(--magnetic-blue-60) !important;
    --brand-accent-border-active: var(--magnetic-blue-45) !important;

    :global(.cds-nav__menu__link) {
      color: var(--magnetic-neutral-3);

      :global(.cds-nav__menu__link__text .cds-tag) {
        background-color: #6b4a3a;
      }
    }
  }
}
