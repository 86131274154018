.wrapper {
  align-items: center;
  border-radius: 2em;
  display: flex;
  font-size: 12px;
  font-weight: 600;

  &.healthy {
    background-color: var(--positive-bg-default);
    color: var(--positive-text-in-default);
  }

  &.low {
    background-color: var(--warning-bg-default);
    color: var(--warning-text-in-default);
  }

  &.medium {
    background-color: var(--severe-warning-bg-default);
    color: var(--warning-text-in-default);
  }

  &.high {
    background-color: var(--negative-bg-default);
    color: var(--negative-text-in-default);
  }
}

.icon {
  align-items: center;
  display: flex;
  padding: 0.25em 0.25em 0.25em 0.375em;

  .low &,
  .medium & {
    // fixes awkward triangle spacing
    margin-left: 0.25em;
  }
}

.status {
  display: flex;
  padding: 0.25em 0.5em 0.25em 0;
  text-transform: capitalize;
}
